<template>
  <div >
    <div class="row">
      <div class="col-md-12">
        <b-card :title="'Create News'">
          <b-card-text
            ><validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <div class="row">
                  <div class="col-md-12">
                    <div class="border rounded p-2 mb-2">
                      <b-media no-body vertical-align="center" class="d-block">
                        <b-media-aside>
                          <b-img
                            :src="thumbnail"
                            style="width: 900px; height: 600px"
                            class="rounded text-center m-auto mb-md-0"
                          />
                        </b-media-aside>
                        <b-media-body class="mt-2">
                          <b-form-file
                            id="icon_image"
                            accept=".jpg, .png, .gif"
                            @change="loadImage($event)"
                            placeholder="Select Icon Image"
                            drop-placeholder="Drop file here..."
                          />
                        </b-media-body>
                      </b-media>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <!-- email -->
                    <b-form-group
                      label="Category"
                      label-for="option_category_id"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="news_category_id"
                        rules="required"
                      >
                        <v-select
                          id="news_category_id"
                          placeholder="Select Category"
                          v-model="form.news_category_id"
                          :options="categories"
                          label="name"
                          :reduce="(categories) => categories.id"
                        >
                          <template #option="{ name }">
                            <span> {{ name }}</span>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <!-- email -->
                    <b-form-group label="Title" label-for="name">
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.title"
                          :state="errors.length > 0 ? false : null"
                          name="name"
                          placeholder="Enter Your Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Short Description"
                      label-for="short description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="short description"
                        rules="required"
                      >
                        <b-form-textarea
                          id="short description"
                          v-model="form.short_description"
                          :state="errors.length > 0 ? false : null"
                          name="short description"
                          placeholder="Enter Your short Description"
                          rows="4"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <div class="d-block" style="height: 350px">
                      <b-form-group label="Description" label-for="description">
                        <validation-provider
                          #default="{ errors }"
                          name="description"
                          rules="required"
                        >
                          <quill-editor
                            id="description"
                            style="height: 250px"
                            class="mb-4"
                            v-model="form.description"
                            name="description"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Enter Your Description"
                          />
                          <div style="clear: both"></div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <!-- email -->
                    <b-form-group label="Meta Title" label-for="meta_title">
                      <validation-provider
                        #default="{ errors }"
                        name="meta_title"
                      >
                        <b-form-input
                          id="meta_title"
                          v-model="form.meta_title"
                          :state="errors.length > 0 ? false : null"
                          name="meta_title"
                          placeholder="Enter Your Meta Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <b-form-group
                      label="Meta Description"
                      label-for="meta_description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="meta_description"
                      >
                        <b-form-textarea
                          id="meta_description"
                          v-model="form.meta_description"
                          :state="errors.length > 0 ? false : null"
                          name="meta_description"
                          placeholder="Enter Your Meta Description"
                          rows="4"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
                <!-- submit buttons -->
                <b-form-checkbox
                  name="is_show"
                  style="margin-top: 5px"
                  v-model="form.is_show"
                  switch
                  inline
                >
                  Is show
                </b-form-checkbox>
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                  status
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  Update
                </b-button>
              </b-form>
            </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      form: {
        id: this.$route.params.id,
        _method: "PUT",
        news_category_id: null,
        title: "",
        short_description: "",
        description: "",
        meta_title: "",
        meta_description: "",
        is_show: true,
        status: true,
        thumbnail: null,
      },
      thumbnail: null,
      options: {},
      permission: {
        edit: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch("getNewsCategory");
    this.Permission();
    this.Edit();
  },
  methods: {
    loadImage(e) {
      this.form.thumbnail = e.target.files[0];
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.thumbnail = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    Update() {
      let fd = new FormData();

      fd.append("id", this.form.id);
      fd.append("news_category_id", this.form.news_category_id);
      fd.append("title", this.form.title);
      fd.append("short_description", this.form.short_description);
      fd.append("description", this.form.description);
      fd.append("meta_title", this.form.meta_title);
      fd.append("meta_description", this.form.meta_description);
      fd.append("is_show", this.form.is_show);
      fd.append("status", this.form.status);
      fd.append("thumbnail", this.form.thumbnail);
      fd.append("_method", this.form._method);

      axios
        .post("/news/" + this.form.id, fd)
        .then((res) => {
          this.s(res.data.message);
          this.getResults();
        })
        .catch((e) => {
          if (e.response.status === 422) {
            if (e.response.data.errors.thumbnail)
              this.e(e.response.data.errors.thumbnail[0]);
            if (e.response.data.errors.title)
              this.e(e.response.data.errors.title[0]);
            if (e.response.data.errors.news_category_id)
              this.e(e.response.data.errors.news_category_id[0]);
            if (e.response.data.errors.short_description)
              this.e(e.response.data.errors.short_description[0]);
            if (e.response.data.errors.description)
              this.e(e.response.data.errors.description[0]);
            if (e.response.data.errors.meta_title)
              this.e(e.response.data.errors.meta_title[0]);
            if (e.response.data.errors.meta_description)
              this.e(e.response.data.errors.meta_description[0]);
            if (e.response.data.errors.is_show)
              this.e(e.response.data.errors.is_show[0]);
            if (e.response.data.errors.status)
              this.e(e.response.data.errors.status[0]);
          }
        });
    },

    Edit() {
      axios
        .get("/news/" + this.form.id)
        .then((res) => {
          this.form.id = res.data.news.id;
          this.form.news_category_id = res.data.news.news_category_id;
          this.form.title = res.data.news.title;
          this.form.short_description = res.data.news.short_description;
          this.form.description = res.data.news.description;
          this.form.meta_title = res.data.news.meta_title;
          this.form.meta_description = res.data.news.meta_description;
          this.thumbnail = res.data.news.thumbnail
            ? this.$store.state.base_url + res.data.news.thumbnail
            : this.getImage(1080, 720);
          this.form.is_show = res.data.news.is_show == 1 ? true : false;
          this.form.status = res.data.news.status == 1 ? true : false;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.Update();
        }
      });
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "news.edit") {
          this.permission.edit = true;
        }
      });
    },
  },
  components: {
    quillEditor,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
  computed: {
    categories() {
      return this.$store.getters.getNewsCategory;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
